import { Routes } from '@angular/router';
import { RoutePath } from './enums';
import { authPageGuard } from './guard';

export default [
  { path: '', redirectTo: RoutePath.Home, pathMatch: 'full' },
  {
    path: RoutePath.Auth,
    canActivate: [authPageGuard],
    loadComponent: () =>
      import('./pages/auth-page/auth-page.component').then(
        (mod) => mod.default,
      ),
  },
  // {
  //   path: RoutePath.Profile,
  //   canActivate: [profilePageGuard],
  //   loadComponent: () =>
  //     import('./pages/profile-page/profile-page.component').then(
  //       (mod) => mod.default,
  //     ),
  // },
  // {
  //   path: RoutePath.Blog,
  //   loadChildren: () => import('./pages/blog-page/blog-routes'),
  // },
  // {
  //   path: RoutePath.ContactUs,
  //   loadComponent: () =>
  //     import('./pages/contact-us-page/contact-us-page.component').then(
  //       (mod) => mod.default,
  //     ),
  // },
  // {
  //   path: RoutePath.FrequentlyAskedQuestion,
  //   loadComponent: () =>
  //     import(
  //       './pages/frequently-asked-question-page/frequently-asked-question-page.component'
  //     ).then((mod) => mod.default),
  // },
  {
    path: RoutePath.Home,
    loadComponent: () =>
      import('./pages/home-page/home-page.component').then(
        (mod) => mod.default,
      ),
  },
  // {
  //   path: RoutePath.Membership,
  //   loadComponent: () =>
  //     import('./pages/membership-page/membership-page.component').then(
  //       (mod) => mod.default,
  //     ),
  // },
  {
    path: RoutePath.PrivacyPolicy,
    loadComponent: () =>
      import(
        './pages/privacy-policy-page/privacy-policy-page.component'
      ).then((mod) => mod.default),
  },
  { path: '**', redirectTo: RoutePath.Home, pathMatch: 'full' },
] as Routes;
