import { Routes } from '@angular/router';
import forestageRoutes from './ui/forestage-routes';

export const appRoot: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./ui/forestage.component').then((mod) => mod.default),
    children: forestageRoutes,
  },
];
