import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { appRoot } from './app-routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { importProvidersFrom } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptors } from 'projects/shared-resources/src/lib/interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { environment } from 'projects/vacation-forestage/src/environments/environment';
import {
  API_CONFIG,
  AZURE_CONFIG,
  THEME_CONFIG,
} from 'projects/shared-resources/src/lib/services';
import { MatDialogModule } from '@angular/material/dialog';
import { MAP_WEBSITE_CODE_TO_THEME } from '../theme';
import { TRANSLATE_CONFIG } from 'projects/shared-resources/src/lib/services/translate.service';
import { WEBSITE_CODES } from '../environments/model';
import { TranslateModule } from '@ngx-translate/core';
import { WEBSITE_CODE } from './tokens';
import { generateMockDataAppConfig } from 'projects/shared-resources/src/lib/interceptor/mockData.interceptor';
import { CAROUSEL } from './ui/pages/home-page/components/vacation-banner/spec-helper/carousel.spec-helper';
import { PROPERTIES } from './ui/pages/home-page/components/properties-explore/spec-helper/properties.spec-helper';

const MOCK_API_DATA = {
  '/carousel$': CAROUSEL,
  '/property$': PROPERTIES,
};

export const appConfig = {
  providers: [
    {
      provide: WEBSITE_CODE,
      useValue: environment.websiteCode,
    },
    {
      provide: API_CONFIG,
      useValue: {
        apiRoot: `${environment.baseUrl}/${environment.websiteCode}`,
        isMockEnabled: environment.isMockEnabled,
      },
    },
    {
      provide: THEME_CONFIG,
      useValue: MAP_WEBSITE_CODE_TO_THEME[environment.websiteCode],
    },
    {
      provide: AZURE_CONFIG,
      useValue: {
        azureUrl: environment.azureUrl,
        azureClientId: environment.azureClientId,
        azureLoginUserFlow: environment.azureLoginUserFlow,
        azureResetPasswordUserFlow: environment.azureResetPasswordUserFlow,
        azureTwoFactorUserFlow: environment.azureTwoFactorUserFlow,
      },
    },
    {
      provide: TRANSLATE_CONFIG,
      useValue: {
        currentToken: environment.websiteCode,
        tokenList: WEBSITE_CODES,
      },
    },
    provideRouter(
      appRoot,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled',
      }),
    ),
    importProvidersFrom(
      BrowserAnimationsModule,
      MatSnackBarModule,
      MatDialogModule,
      HttpClientModule,
      TranslateModule.forRoot(),
    ),
    ...(environment.isMockEnabled
      ? generateMockDataAppConfig(MOCK_API_DATA)
      : []),
    ...httpInterceptors,
  ],
};
