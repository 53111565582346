import { GetListResponse } from 'projects/vacation-cms/src/app/ui/models/http';
import { Carousel } from '../models';
import { CarouselKey, CarouselStatus } from '../enums';

const data: Carousel[] = [
  {
    [CarouselKey.Id]: 1,
    [CarouselKey.Title]: 'Summer Vacation',
    [CarouselKey.Snippet]: `
<div style="position: relative; width: 100%;">
<p style="margin: 0;"><img id="templateBackground" style="height: 100%; min-height: 368px; width: 100%; object-fit: cover;" src="assets/img/png/homePageCarouselTemplate.png" alt="homePageCarouselTemplate.png"></p>
<div style="width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; text-align: center; position: absolute; top: 0; left: 0;">
<p style="display: flex; justify-content: center; text-align: center;"><span style="display: flex; justify-content: center; width: 55%; font-size: 56px; color: rgb(255, 255, 255);"> <strong class="wysiwyg-template-title"> Homepage Carousel Title </strong> </span></p>
<p style="text-align: center;"><a class="wysiwyg-cta-btn" style="border-radius: 9999px; text-decoration: none; white-space: nowrap;" title="call to action" href="https://yes-travel.dev.logicsolutions.com"> Let's Vacation </a></p>
<p>&nbsp;</p>
</div>
</div>`,
    [CarouselKey.Status]: CarouselStatus.Published,
    [CarouselKey.Order]: 1,
  },
  {
    [CarouselKey.Id]: 2,
    [CarouselKey.Title]: 'Winter Wonderland',
    [CarouselKey.Snippet]: `
<div style="position: relative; width: 100%;">
<p style="margin: 0;"><img id="templateBackground" style="height: 100%; min-height: 368px; width: 100%; object-fit: cover;" src="assets/img/png/homePageCarouselTemplate.png" alt="homePageCarouselTemplate.png"></p>
<div style="width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; text-align: center; position: absolute; top: 0; left: 0;">
<p style="display: flex; justify-content: center; text-align: center;"><span style="display: flex; justify-content: center; width: 55%; font-size: 56px; color: rgb(255, 255, 255);"> <strong class="wysiwyg-template-title"> Homepage Carousel Title </strong> </span></p>
<p style="text-align: center;"><a class="wysiwyg-cta-btn" style="border-radius: 9999px; text-decoration: none; white-space: nowrap;" title="call to action" href="https://yes-travel.dev.logicsolutions.com"> Let's Vacation </a></p>
<p>&nbsp;</p>
</div>
</div>`,
    [CarouselKey.Status]: CarouselStatus.Published,
    [CarouselKey.Order]: 2,
  },
  {
    [CarouselKey.Id]: 3,
    [CarouselKey.Title]: 'Spring Break',
    [CarouselKey.Snippet]: `
<div style="position: relative; width: 100%;">
<p style="margin: 0;"><img id="templateBackground" style="height: 100%; min-height: 368px; width: 100%; object-fit: cover;" src="assets/img/png/homePageCarouselTemplate.png" alt="homePageCarouselTemplate.png"></p>
<div style="width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; text-align: center; position: absolute; top: 0; left: 0;">
<p style="display: flex; justify-content: center; text-align: center;"><span style="display: flex; justify-content: center; width: 55%; font-size: 56px; color: rgb(255, 255, 255);"> <strong class="wysiwyg-template-title"> Homepage Carousel Title </strong> </span></p>
<p style="text-align: center;"><a class="wysiwyg-cta-btn" style="border-radius: 9999px; text-decoration: none; white-space: nowrap;" title="call to action" href="https://yes-travel.dev.logicsolutions.com"> Let's Vacation </a></p>
<p>&nbsp;</p>
</div>
</div>`,
    [CarouselKey.Status]: CarouselStatus.Published,
    [CarouselKey.Order]: 3,
  },
];

export const CAROUSEL: GetListResponse<Carousel> = {
  is_success: true,
  message: '',
  amount: data.length,
  data,
};
