import { inject, Injectable, InjectionToken } from '@angular/core';
import { BackendRoute } from '../enums/api';

export const API_CONFIG = new InjectionToken<{
  apiRoot: string;
  isMockEnabled: boolean;
}>('[Travel] api root');

@Injectable({
  providedIn: 'root',
})
export class ApiUtilsService {
  public apiConfig = inject(API_CONFIG);

  buildUrl(endpoint: string): string {
    console.log({ isMockEnabled: this.apiConfig.isMockEnabled });
    if (this.apiConfig.isMockEnabled) {
      const endpointSplit = this.converseUrlToSnakeCase(endpoint);
      const mockPrefix = this.converseUrlToSnakeCase(
        `${this.apiConfig.apiRoot}/${BackendRoute.Api}`,
      );
      return `${mockPrefix}${endpointSplit}`;
    }
    return endpoint;
  }

  converseUrlToSnakeCase(url: string) {
    const urlSplit = url.split('/').filter((item) => item !== '');
    if (urlSplit.length === 1) return url;
    // const pop = urlSplit.pop();
    // const id = Number(pop);
    // return `/${urlSplit.join('_')}${
    //   Number.isNaN(id) ? `_${pop}` : `/${id}`
    // }`;
    return `/${urlSplit.join('_')}`;
  }

  generateParamsObject = (object: Record<string, unknown>) => {
    const paramsObject = {};
    Object.keys(object).forEach((objectKey) => {
      const queryValue = object[objectKey as keyof object];
      if (queryValue !== undefined && queryValue !== null) {
        Object.assign(paramsObject, { [objectKey]: queryValue });
      }
    });
    return paramsObject;
  };
}
