export enum CarouselKey {
  Id = 'Id',
  Title = 'title',
  Snippet = 'snippet',
  Status = 'status',
  Order = 'order',
}

export enum CarouselStatus {
  Published = 'published',
  Draft = 'draft',
  PendingReview = 'pendingReview',
}
