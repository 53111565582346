import { AuthService } from 'projects/shared-resources/src/lib/services/auth.service';
import { RoutePath } from 'projects/vacation-forestage/src/app/ui/enums';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const authPageGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  if (authService.isLoggedIn()) {
    const router = inject(Router);
    router.navigate([RoutePath.Profile]);
    return false;
  }
  return true;
};
