import { AuthService } from 'projects/shared-resources/src/lib/services/auth.service';
import { RoutePath } from 'projects/vacation-forestage/src/app/ui/enums';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';

export const profilePageGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const isLoggedOut = authService.isLoggedOut();
  if (isLoggedOut) {
    try {
      await lastValueFrom(authService.refreshAccessToken());
    } catch {
      router.navigate([RoutePath.Auth]);
      return false;
    }
  }
  return true;
};
