import { Property, PropertyCategory } from '../models';

const data: Property[] = [
  {
    id: 1,
    isFeaturedOffer: true,
    name: 'Tahiti Village',
    state: 'Nevada',
    city: 'Las Vegas',
    regularBookingLink: 'https://bookings.travelclick.com/96810',
    mainWebsite: 'https://www.tahitivillage.com',
    category: PropertyCategory.LasVegas,
    description:
      'Lorem ipsum dolor sit amet consectetur. Lacus magna nisi ullamcorper sollicitudin.',
  },
  {
    id: 2,
    isFeaturedOffer: true,
    name: 'Tahiti Resort',
    state: 'Nevada',
    city: 'Las Vegas',
    regularBookingLink: 'https://bookings.travelclick.com/96832',
    mainWebsite: 'http://www.tahitiresortlv.com',
    category: PropertyCategory.LasVegas,
    description:
      'Lorem ipsum dolor sit amet consectetur. Lacus magna nisi ullamcorper sollicitudin.',
  },
  {
    id: 3,
    isFeaturedOffer: false,
    name: 'Club de Soléil',
    state: 'Nevada',
    city: 'Las Vegas',
    regularBookingLink: 'https://bookings.travelclick.com/96812',
    mainWebsite: 'http://www.clubdesoleil.com',
    category: PropertyCategory.LasVegas,
    description:
      'Lorem ipsum dolor sit amet consectetur. Lacus magna nisi ullamcorper sollicitudin.',
  },
  {
    id: 4,
    isFeaturedOffer: true,
    name: 'Sands of Kahana',
    state: 'Hawaii',
    city: 'Maui',
    regularBookingLink: 'https://bookings.travelclick.com/96818',
    mainWebsite: 'http://www.sandsofkahanaresort.com',
    category: PropertyCategory.Hawaii,
    description:
      'Lorem ipsum dolor sit amet consectetur. Lacus magna nisi ullamcorper sollicitudin.',
  },
  {
    id: 5,
    isFeaturedOffer: false,
    name: 'Kahana Beach',
    state: 'Hawaii',
    city: 'Maui',
    regularBookingLink: 'https://bookings.travelclick.com/96815',
    mainWebsite: 'http://www.kahanabeach.com',
    category: PropertyCategory.Hawaii,
    description:
      'Lorem ipsum dolor sit amet consectetur. Lacus magna nisi ullamcorper sollicitudin.',
  },
  {
    id: 6,
    isFeaturedOffer: false,
    name: 'Kahana Villa',
    state: 'Hawaii',
    city: 'Maui',
    regularBookingLink: 'https://bookings.travelclick.com/96817',
    mainWebsite: 'http://www.kahanavillavc.com',
    category: PropertyCategory.Hawaii,
    description:
      'Lorem ipsum dolor sit amet consectetur. Lacus magna nisi ullamcorper sollicitudin.',
  },
  {
    id: 7,
    isFeaturedOffer: false,
    name: 'Maui Beach',
    state: 'Hawaii',
    city: 'Maui',
    regularBookingLink: 'https://bookings.travelclick.com/96814',
    mainWebsite: 'http://www.mauibeachvc.com',
    category: PropertyCategory.Hawaii,
    description:
      'Lorem ipsum dolor sit amet consectetur. Lacus magna nisi ullamcorper sollicitudin.',
  },
  {
    id: 8,
    isFeaturedOffer: false,
    name: 'Maui Banyan',
    state: 'Hawaii',
    city: 'Maui',
    regularBookingLink: 'https://bookings.travelclick.com/96803',
    mainWebsite: 'http://www.mauibanyanvc.com',
    category: PropertyCategory.Hawaii,
    description:
      'Lorem ipsum dolor sit amet consectetur. Lacus magna nisi ullamcorper sollicitudin.',
  },
  {
    id: 9,
    isFeaturedOffer: true,
    name: 'Gardens at West Maui',
    state: 'Hawaii',
    city: 'Maui',
    regularBookingLink: 'https://bookings.travelclick.com/96819',
    mainWebsite: 'http://www.gardenswestmaui.com',
    category: PropertyCategory.Hawaii,
    description:
      'Lorem ipsum dolor sit amet consectetur. Lacus magna nisi ullamcorper sollicitudin.',
  },
  {
    id: 10,
    isFeaturedOffer: false,
    name: 'Hono Koa',
    state: 'Hawaii',
    city: 'Maui',
    regularBookingLink: 'https://bookings.travelclick.com/96821',
    mainWebsite: 'http://www.honokoavc.com',
    category: PropertyCategory.Hawaii,
    description:
      'Lorem ipsum dolor sit amet consectetur. Lacus magna nisi ullamcorper sollicitudin.',
  },
  {
    id: 11,
    isFeaturedOffer: false,
    name: 'Kona Islander',
    state: 'Hawaii',
    city: 'Kona',
    regularBookingLink: 'https://bookings.travelclick.com/96816',
    mainWebsite: 'https://www.konaislandervc.com',
    category: PropertyCategory.Hawaii,
    description:
      'Lorem ipsum dolor sit amet consectetur. Lacus magna nisi ullamcorper sollicitudin.',
  },
  {
    id: 12,
    isFeaturedOffer: false,
    name: 'The Chateau at Lake La Quinta',
    state: 'California',
    city: 'Palm Springs',
    regularBookingLink: 'https://bookings.travelclick.com/96820',
    mainWebsite: 'http://www.thechateaulakelaquinta.com',
    category: PropertyCategory.California,
    description:
      'Lorem ipsum dolor sit amet consectetur. Lacus magna nisi ullamcorper sollicitudin.',
  },
  {
    id: 13,
    isFeaturedOffer: false,
    name: 'Villas at Legal Palms',
    state: 'Florida',
    city: 'Davenport',
    regularBookingLink: 'https://bookings.travelclick.com/96807',
    mainWebsite: 'http://www.villasatregalpalms.com',
    category: PropertyCategory.Florida,
    description:
      'Lorem ipsum dolor sit amet consectetur. Lacus magna nisi ullamcorper sollicitudin.',
  },
];

export const PROPERTIES = {
  is_success: true,
  message: '',
  amount: data.length,
  data,
};
