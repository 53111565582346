export const COLORS = {
  'sunset-orange': '255 102 1',
  'morning-orange-300': '255 163 102',
  'morning-orange-400': '246 172 79',
  'morning-orange-500': '247 153 28',
  'morning-orange': '247 153 28',
  'sunny-yellow-400': '255 242 204',
  'sunny-yellow': '255 204 51',
  'sunny-yellow-500': '255 204 51',
  'beach-sand-20': '255 246 234',
  'beach-sand': '255 204 153',
  'beach-sand-500': '255 204 153',
  white: '255 255 255',
  'night-sky-dark-blue-8': '235 240 241',
  'night-sky-dark-blue-20': '204 217 221',
  'night-sky-dark-blue-80': '51 102 118',
  'night-sky-dark-blue-100': '0 64 84',
  'night-sky-dark-blue': '9 64 84',
  'night-sky-dark-blue-500': '9 64 84',
  'night-sky-dark-blue-400': '32 77 116',
  'ocean-blue-100': '0 115 135',
  'ocean-blue': '0 115 135',
  'mint-green': '114 199 186',
  'strawberry-ice-cream': '252 153 173',
  'red-700': '166 30 12',
  'red-600': '187 0 0',
  'red-500': '235 87 87',
  red: '235 87 87',
  gray: '51 51 51',
  'gray-1': '51 51 51',
  'gray-2': '79 79 79',
  'gray-2.5': '105 105 105',
  'gray-3': '130 130 130',
  'gray-4': '189 189 189',
  'gray-5': '224 224 224',
  'gray-6': '242 242 242',
  'gray-100': '249 249 249',
  'gray-300': '225 227 234',
  'gray-500': '161 165 183',
  'gray-600': '126 130 153',
  'gray-900': '24 28 50',
  black: '0 0 0',
  blue: '0 0 255',
  'cerulean-blue-600': '22 98 199',
  'cerulean-blue-500': '41 110 198',
  'cobalt-blue-500': '0 88 173',
  'cobalt-blue-400': '0 103 184',
  'dark-blue-20': '205 220 230',
  'dark-blue': '3 79 132',
  'light-blue': '238 249 253',
  'light-blue-100': '87 193 239',
} as const;

export const THEME_COLORS_LIST = [
  /**
   * These are the main colors in the design, used for primary brand identification and key UI elements like buttons and headers.
   */
  'primary',
  /**
   * These colors support the primary colors, often used for secondary buttons or to highlight parts of the design.
   */
  'secondary',
  /**
   * These are additional colors, often used for backgrounds or decorations to enrich the design.
   */
  'tertiary',
  /**
   * These are additional colors, often used for backgrounds or decorations to enrich the design.
   */
  'tertiary-layer',
  /**
   * These are additional colors, often used for backgrounds or decorations to enrich the design.
   */
  'tertiary-selector',
  /**
   * These are additional colors, often used for backgrounds or decorations to enrich the design.
   */
  'tertiary-card',
  'tertiary-a-hover',
  'tertiary-hr',
  'tertiary-ghost-input',
  'tertiary-ghost-input-suffix',
  /**
   * Neutral colors are typically used for backgrounds, borders, shadows, and secondary text.
   */
  'neutral',
  /**
   * Used for the background color of pages or components.
   */
  'background',
  /**
   * Used for the loader color.
   */
  'loader',
] as const;
