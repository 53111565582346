import { AnyObject } from 'projects/vacation-cms/src/app/ui/models/util';

export enum PropertyKey {
  Id = 'id',
  Name = 'name',
  RegularBookingLink = 'regularBookingLink',
  MainWebsite = 'mainWebsite',
  City = 'city',
  State = 'state',
  Category = 'category',
  Description = 'description',
  IsFeaturedOffer = 'isFeaturedOffer',
}

export interface Property extends AnyObject {
  [PropertyKey.Id]: number;
  [PropertyKey.Name]: string;
  [PropertyKey.RegularBookingLink]: string;
  [PropertyKey.MainWebsite]: string;
  [PropertyKey.City]: string;
  [PropertyKey.State]: string;
  [PropertyKey.Category]: PropertyCategory;
  [PropertyKey.Description]: string | null;
  [PropertyKey.IsFeaturedOffer]: boolean;
}

export enum PropertyCategory {
  LasVegas = 'Las Vegas',
  Hawaii = 'Hawaii',
  California = 'California',
  Florida = 'Florida',
}
