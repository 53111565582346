export enum RoutePath {
  Auth = 'auth',
  Blog = 'blog',
  ContactUs = 'contact-us',
  FrequentlyAskedQuestion = 'faq',
  Home = 'home',
  Profile = 'profile',
  PrivacyPolicy = 'privacy',
  TermOfUs = 'term',
  Property = 'property',
  //---

  About = 'about',
  Destination = 'destination',
  Product = 'product',
}
